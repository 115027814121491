/* Generated by Glyphter (http://www.glyphter.com) on  Sat Sep 12 2020*/
@font-face {
    font-family: 'share';
    src: url('../fonts/share.eot');
    src: url('../fonts/share.eot?#iefix') format('embedded-opentype'),
         url('../fonts/share.woff') format('woff'),
         url('../fonts/share.ttf') format('truetype'),
         url('../fonts/share.svg#share') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'share';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-share:before{content:'\0041';}
.icon-cancel-circle:before{content:'\0042';}
.icon-cancel:before{content:'\0043';}
.icon-download:before{content:'\0044';}
.icon-download-circle:before{content:'\0045';}
.icon-info:before{content:'\0046';}
.icon-info-circle:before{content:'\0046';}
.icon-check-circle:before{content:'\0047';}
