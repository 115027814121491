@import "./components/UI/modal/modal";
@import "./css/icons.css";

*{
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body,html,#root{
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Roboto Condensed', sans-serif;
}
.main-container{
  height: 100%;
  width: 100%;
  overflow: hidden auto;
}

.ro{border:1px solid red;}
.wc{width:100%;}
.ct{color:#DD0014;}
.dn{display: none!important;}

button,input,select,textarea{
  &:focus{
    outline: none;
  }
}

input:required {
  box-shadow:none;
}
input:invalid {
  box-shadow:0 0 3px red;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  //padding-left: 35px;
  background-image: url('./img/caret.png');
  background-size: 18px auto;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
}

.pagar > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  //padding-left: 35px;
  background-image: url('./img/caret-gray.png')!important;
  background-size: 18px auto;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
}

select::-ms-expand {
  display: none;
}

.title2{
  font-weight: bold;
  font-size: 18px;
}
.bb{
  border-bottom:1px solid rgba(0,0,0,.15);
}

.fr{
  font-family: 'Roboto';
}
.w300{
  font-weight: 300;
}

@keyframes girar {
  from{transform: rotate(0)}
  to{transform: rotate(360deg)}
}
