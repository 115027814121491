#cont-modal{
  position: fixed;
  z-index: 99999;
  top: 0;
}
.modal-container{
  position: fixed;
  z-index: 2;
  height: 100vh!important;
  width: 100vw;
  top: 0;
  left: 0;

  .sombra-modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 0;
  }

  .masOscuro{
    background-color: rgba(0,0,0,.8);
  }

  .area{
    transform-style: preserve-3d;
    perspective: 1500px;

  }

  .card,.gira{
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    opacity: 0;
    border-radius: 16px;
    transform: scale(.8) rotateX(-180deg);
    transition: all 600ms cubic-bezier(0.175, 0.885, 0.320, 1.275);

    .content{
      padding-bottom: 0;
      min-width: 300px;
      min-height: 50px;
      transition: all 600ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }

    .botones{
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(18px);
    }

    .close{
      font-size: 14px;
      position: absolute;
      top: .5em;
      right: .5em;
      cursor: pointer;
      z-index:999;
    }

    .close2{
      width: 20px;
      top: .75em;
      right: .75em;
    }
  }

  .gira{
    box-shadow: none;
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: all 500ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}


.fadeUp-enter {
  opacity: 0;
  transform: translateY(30px);
}
.fadeUp-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s cubic-bezier(0.075, 0.820, 0.165, 1.000);
}

.fade-enter-done{
  .card,.gira{
    opacity: 1;
    transform:scale(1) rotateX(0);
  }

  .cColombia{
    transform:scale(1);
    opacity: 1;
  }
}

.fade-exit{
  .card,.gira{
    opacity: 0;
    transform:scale(.9);
  }
  .cColombia{
    opacity: 0;
    transform:scale(.5);
  }
}

.desenfocar{
  filter: blur(1.5rem);
}

.btn-secundario > button{
  background: #7f7f7f!important;
  color: white!important;
  border: none!important;
  width: 100%!important;
  min-height: 40px!important;
  border-radius: 22px!important;
  cursor: pointer!important;
  font-weight: bold!important;
}

.btn-primario > button {
  font-weight: bold!important;
}